<template>
  <VDialog
    v-model="dialogOpen"
    persistent
    width="1000px"
  >
    <VCard>
      <VAppBar
        flat
        dark
        color="primary"
      >
        <VBtn
          icon
          dark
          @click="closeDialog"
        >
          <VIcon>mdi-close</VIcon>
        </VBtn>

        <VToolbarTitle>
          Bulk MATLAB Script Downloader Tool v0.3
        </VToolbarTitle>
      </VAppBar>

      <VScaleTransition mode="in-out">
        <VCardText
          v-show="loading"
        >
          <v-row>
            <v-col
              cols="6"
              offset="3"
            >
              <div class="text-center">
                <span class="text-h5 grey--text">
                  Refreshing experiments...
                </span>
              </div>
            </v-col>

            <v-col
              class="pb-2 pt-2"
              cols="6"
              offset="3"
            >
              <div class="text-center">
                <VProgressCircular
                  :size="100"
                  :width="10"
                  color="primary"
                  :indeterminate="true"
                />
              </div>
            </v-col>
          </v-row>
        </VCardText>
      </VScaleTransition>

      <VScaleTransition mode="out-in">
        <VCardText v-show="!loading">
          <v-row>
            <!-- Step 1 of the process, selecting the experiments -->
            <v-col cols="12">
              <VCard flat>
                <VCardTitle class="text-h6 grey--text text--darken-1">
                  Step 1: Select a set of experiments to fetch
                </VCardTitle>

                <VCardText>
                  <VTabs
                    v-model="activeTab"
                  >
                    <VTab
                      v-for="tab in tabTitles"
                      :key="`tabKey`+tab.id"
                      :disabled="loading || downloading"
                      ripple
                    >
                      {{ tab.title }}
                    </VTab>

                    <VTabItem
                      v-for="tab in tabTitles"
                      :key="`tabItemKey`+tab.index"
                    >
                      <VCard flat>
                        <!-- This is the range selection method-->
                        <VCardText
                          v-if="tab.id === 'range'"
                        >
                          <v-row v-if="!loading">
                            <v-col cols="1">
                              <VTextField
                                v-model="selectedRange[0]"
                                class="mt-0"
                                :disabled="downloading"
                                hide-details
                                single-line
                                type="number"
                              />
                            </v-col>
                            <v-col cols="10">
                              <VRangeSlider
                                v-model="selectedRange"
                                class="pr-2 pl-2"
                                :disabled="downloading"
                                :min="expsMin"
                                :max="expsMax"
                                :step="1"
                              />
                            </v-col>
                            <v-col cols="1">
                              <VTextField
                                v-model="selectedRange[1]"
                                class="mt-0"
                                :disabled="downloading"
                                hide-details
                                single-line
                                type="number"
                              />
                            </v-col>
                          </v-row>
                        </VCardText>
                        <!-- This is the string selection-->
                        <VCardText
                          v-else-if="tab.id === 'string'"
                        >
                          String selection (not implemented)
                        </VCardText>
                        <!-- This is the selection from the cache recorded to in the experiment detail -->
                        <VCardText
                          v-else-if="tab.id === 'cache'"
                        >
                          Cache selection (not implemented)
                        </VCardText>
                      </VCard>
                    </VTabItem>
                  </VTabs>
                </VCardText>
              </VCard>
            </v-col>

            <!-- Step 2 of the process, downloading the MATLAB scripts -->
            <v-col cols="12">
              <VCard flat>
                <VCardTitle class="text-h6 grey--text text--darken-1">
                  Step 2: Fetch the MATLAB scripts
                </VCardTitle>

                <VCardText>
                  <v-row>
                    <v-col class="shrink">
                      <VBtn
                        large
                        color="primary"
                        rounded
                        :disabled="downloading || loading"
                        @click="fetchExpScripts"
                      >
                        <VIcon left>
                          mdi-bone
                        </VIcon>
                        Fetch
                      </VBtn>
                    </v-col>
                    <v-col
                      class="pt-2"
                    >
                      <VProgressLinear
                        v-model="downloadProgress"
                        :active="!loading"
                      />
                    </v-col>
                  </v-row>
                </VCardText>
              </VCard>
            </v-col>

            <!-- Step 3 of the process, download the fetched data -->
            <v-col cols="12">
              <VCard flat>
                <VCardTitle class="text-h6 grey--text text--darken-1">
                  Step 3: Download the scripts to a text file
                </VCardTitle>

                <VCardText>
                  <v-row>
                    <v-col>
                      <VBtn
                        large
                        color="primary"
                        rounded
                        :disabled="!downloadDone"
                        @click.stop="downloadBlob"
                      >
                        <VIcon left>
                          mdi-download
                        </VIcon>
                        Download
                      </VBtn>

                      <VBtn
                        large
                        color="blue"
                        rounded
                        :disabled="!downloadClicked"
                        @click="closeDialog"
                      >
                        <VIcon left>
                          mdi-check
                        </VIcon>
                        Finish
                      </VBtn>
                    </v-col>
                  </v-row>
                </VCardText>
              </VCard>
            </v-col>
          </v-row>
        </VCardText>
      </VScaleTransition>
    </VCard>
  </VDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { EventBus } from '../scripts/event_bus'

export default {
  name: 'BulkDownloaderTool',
  props: {
    dialogOpen: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      activeTab: null,
      tabTitles: [
        { index: '0', id: 'range', title: 'From Range' },
        { index: '1', id: 'string', title: 'From String' },
        { index: '2', id: 'cache', title: 'From Cache' }
      ],
      selectedRange: [0, 50],
      downloading: false,
      downloadClicked: false,
      fetchList: [],
      fetchesDone: 0,
      scriptStore: [],
      scriptsBlob: null
    }
  },
  computed: {
    ...mapGetters({
      experimentList: 'expList',
      loading: 'loading'
    }),
    expsMax: function () {
      return this.experimentList.length > 0 ? this.experimentList[this.experimentList.length - 1].id : 100
    },
    expsMin: function () {
      return this.experimentList.length > 0 ? this.experimentList[0].id : 0
    },
    downloadProgress: function () {
      return this.fetchList.length > 0 ? (this.fetchesDone * 100) / this.fetchList.length : 0
    },
    downloadDone: function () {
      return this.fetchList.length > 0 ? this.fetchesDone === this.fetchList.length : false
    }
  },
  mounted () {
    this.resetDialog()
  },
  methods: {
    ...mapActions({
      refreshExps: 'refreshExpList'
    }),
    closeDialog: function () {
      this.$emit('closeClicked')
      this.resetDialog()
    },
    resetDialog: function () {
      this.downloading = false
      this.fetchesDone = 0
      this.scriptStore = []
      this.fetchList = []
      this.scriptsBlob = null
      this.downloadClicked = false
    },
    populateFetchList: function (selectMethod) {
      switch (selectMethod) {
        case 0:
          console.log('Range selection method used, populating by min/max range:', this.selectedRange)
          // Now actually do the populating, by starting at min, stepping by 1, and stopping at max.
          this.fetchList = []
          for (let i = this.selectedRange[0]; i <= this.selectedRange[1]; i++) {
            const thisExpDetail = this.experimentList.find(exp => {
              return parseInt(exp.id) === i
            })

            // Some experiments don't exist, so don't ask the server for them.
            if (thisExpDetail) {
              // Some are deleted (have the hidden flag set), so don't ask for them.
              if (parseInt(thisExpDetail.hidden) === 0) {
                this.fetchList.push(i)
              }
            }
          }
          break
      }
    },
    generateFetchPostData: function (expId) {
      const dataToPost = []

      dataToPost.push({ key: 'experiment_id', value: expId })
      // dataToPost.push({ key: 'step', value: this.currentPage })
      dataToPost.push({ key: 'action', value: 'refresh' })

      return dataToPost
    },
    fetchExpScripts: function () {
      // First thing that needs to be done is to produce the list of experiment ids to iterate over.
      // This will depend on which selection method was used, luckily I have a method prepared...
      this.populateFetchList(parseInt(this.activeTab))

      // The fetch list requested by the user may not have contained any valid experiments.
      if (this.fetchList.length > 0) {
        // If the user clicks on this, and the list had valid bits,
        // then the current download becomes out-of-date.
        this.downloadClicked = false

        // Prepare for the download by resetting some things
        this.downloading = true
        this.fetchesDone = 0
        this.scriptStore = []

        // Ok big boy, here we go with the ridiculous download
        this.fetchList.forEach(expFetchId => {
          this.$root.$data.si.getSingleExpData(this.generateFetchPostData(expFetchId), response => {
            // First, check the experiment exists
            if (!response.data.errors) {
              // Now we need to check that the last page is the done page with the MATLAB scripts
              const lastPage = response.data.pages[response.data.pages.length - 1]
              if (lastPage.title === 'Done') {
                // Now we need to see if there is any code there
                if (lastPage.groups.length > 0) {
                  const rawScriptLines = []
                  lastPage.groups.forEach(group => {
                    group.fields.forEach(rawLine => {
                      if (rawLine.type === 'code') {
                        rawScriptLines.push(rawLine.value)
                      }
                    })
                  })

                  this.scriptStore.push({
                    expId: expFetchId,
                    scriptLines: rawScriptLines
                  })
                }
              }
            }

            this.fetchesDone++

            // Check to see if this was the last downloaded script, if so, call the finish method.
            if (this.downloadDone) {
              this.fetchesComplete()
              this.downloading = false
            }
          })
        })
      } else {
        EventBus.$emit('info', 'No valid experiments found in requested set.')
      }
    },
    fetchesComplete: function () {
      const fileHeader = '%% Matlab scripts downloaded from CMSDB website to upload files with and stuff, cool.' +
        '\n%% Downloaded at ' + moment().format('h:mm:ss a on dddd, Do MMMM YYYY') + '\n\n'

      // Now we can generate the text file to be downloaded by compressing the text into a single line
      const singleLineScripts = this.scriptStore.reduce((finalLine, currentExpEntry) => {
        const headerLine = '%%%%%%%%%% Exp ID: ' + currentExpEntry.expId + '\n'

        const scriptLines = currentExpEntry.scriptLines.reduce((expLine, currentLine) => {
          return expLine + currentLine + '\n'
        })

        return finalLine + headerLine + scriptLines + '\n\n'
      }, fileHeader)

      // Finally, generate the blob from the single line of text
      this.scriptsBlob = new Blob([singleLineScripts], { type: 'text/plain;charset=utf-8' })
    },
    downloadBlob: function () {
      // Double-check that the download is done and the blob exists
      if (this.downloadDone && this.scriptsBlob) {
        // Ok, we are all good. Set-up the download!
        // First, lets provide a default name.
        const defaultName = 'ExpScripts-' + moment().format('YYYY-MM-DD-HH_mm') + '.txt'

        // Now we can prompt the user to download the file.
        saveAs(this.scriptsBlob, defaultName)

        // And mark that they have done this
        this.downloadClicked = true
      }
    }
  }
}
</script>

<style scoped>

</style>
